
import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const GalleryGridItem = ({ item, deleteItemClicked, editItemClicked }) => {
    return (
        <div className="gallery-grid-item" key={item.id}>
            <img src={item.imageUrl} alt="imageurl1" />
            <img src={item.imageUrl2} alt="imageurl2" />
            <p id="grid-item-title" > {item.title} </p>

            <button className="delete-btn bottom-right-btn" onClick={() => deleteItemClicked(item)} >
                <FontAwesomeIcon icon={faTrashAlt} />
            </button>
            <button className="edit-btn" onClick={() => editItemClicked(item)} >
                <FontAwesomeIcon icon={faEllipsisV} />
            </button>
        </div>
    )
}

export default GalleryGridItem
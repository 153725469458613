import React, { useEffect } from 'react';
import fire from '../../Helpers/fire';
import FileUploader from '../../Helpers/FileUploader'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTrashAlt, faCheck } from "@fortawesome/free-solid-svg-icons";

const ServiceEdit = ({ services, selectedRow, createdNew, deletedItem }) => {

    React.useEffect(() => {
        setServiceId(services[selectedRow].id)
        setCurrentServiceName(services[selectedRow].title)
        setServiceImageUrl(services[selectedRow].imageUrl)
        setServiceDescription(services[selectedRow].descriptionText)
        setServiceRedirectURL(services[selectedRow].redirectUrl)
        setSubServices(services[selectedRow].sub_services ?? [])
        setNewSubServiceTitle('')
        setNewSubServiceUrl('')
    }, [selectedRow, services])

    const [serviceID, setServiceId] = React.useState(services[selectedRow].id)
    const [serviceName, setCurrentServiceName] = React.useState(services[selectedRow].title)
    const [serviceRedirectURL, setServiceRedirectURL,] = React.useState(services[selectedRow].redirectUrl)
    const [serviceImageUrl, setServiceImageUrl] = React.useState(services[selectedRow].imageUrl)
    const [serviceDescription, setServiceDescription] = React.useState(services[selectedRow].descriptionText)
    const [selectedFile, setSelectedFile] = React.useState(null);

    const [subServices, setSubServices] = React.useState(services[selectedRow].sub_services ?? [])
    const [newSubServiceTitle, setNewSubServiceTitle] = React.useState('')
    const [newSubServiceUrl, setNewSubServiceUrl] = React.useState('')

    const handleDelete = async () => {
        const db = fire.firestore()
        if (serviceID !== '') { await db.collection('services').doc(serviceID).delete() }
        deletedItem(serviceID)
    }

    const handleSave = async () => {
        const db = fire.firestore()
        if (serviceID === "") {
            if (selectedFile == null) {
                alert('item')
                return
            }
            const item = await db.collection('services').add({
                "title": serviceName,
                "imageUrl": serviceImageUrl,
                "redirectUrl": serviceRedirectURL,
                "descriptionText": serviceDescription,
                "sub_services": subServices
            }, { merge: true })

            if (selectedFile != null) {
                await uploadFile(item.id)
            }
        } else {
            await db.collection('services').doc(serviceID).set({
                "title": serviceName,
                "imageUrl": serviceImageUrl,
                "redirectUrl": serviceRedirectURL,
                "descriptionText": serviceDescription,
                "sub_services": subServices
            }, { merge: true })
            if (selectedFile != null) {
                await uploadFile(serviceID)
            }
        }

        createdNew()
    }

    const uploadFile = async (serviceID) => {
        const storage = fire.storage().ref()
        const extType = selectedFile.type === "image/png" ? ".png" : ".jpg"
        const fullPath = `services/${serviceID}${extType}`
        const upload = await storage.child(fullPath).put(selectedFile)
        const downloadURL = await upload.ref.getDownloadURL()
        return await fire.firestore().collection('services').doc(serviceID).set({
            "imageUrl": downloadURL,
        }, { merge: true })
    }

    function updateTitleForSubService(index, event) {
        const newServices = [...subServices];
        newServices[index].title = event.target.value;
        setSubServices(newServices);
    }
    function updateUrlForSubService(index, event) {
        const newServices = [...subServices];
        newServices[index].redirect_url = event.target.value;
        setSubServices(newServices);
    }

    const createNewTapped = async () => {
        if (newSubServiceTitle === '') {
            alert('Morate staviti ime')
            return
        }
        if (newSubServiceUrl === '') {
            alert('Morate staviti url')
            return
        }

        let newSubServices = [...subServices]
        newSubServices.push({ title: newSubServiceTitle, redirect_url: newSubServiceUrl })
        services[selectedRow].sub_services = newSubServices
        loadNewSubServicesTo(newSubServices)
    }
    const loadNewSubServicesTo = (array) => {
        setSubServices(array)
        setNewSubServiceUrl('')
        setNewSubServiceTitle('')
    }
    const deleteSubService = (index) => {
        let localServices = [...subServices]
        localServices.splice(index, 1);
        services[selectedRow].sub_services = localServices
        loadNewSubServicesTo(localServices)
    }
    return (
        <div className="edit-service-container">
            <input type="text"
                placeholder="Ime usluge"
                name="Title"
                className="title-input"
                required value={serviceName}
                onChange={(e) => setCurrentServiceName(e.target.value)} />

            <input type="text"
                placeholder="Redirekt URL"
                name="URL"
                className="url-input"
                required value={serviceRedirectURL}
                onChange={(e) => setServiceRedirectURL(e.target.value)} />
            <FileUploader
                inputName="Izaberi sliku"
                handleFile={(file) => setSelectedFile(file)}
                imageUrl={serviceImageUrl}
            />

            <label>Opis</label>
            <textarea name="description" id="description-input" cols="10" rows="4" value={serviceDescription} placeholder="Opis usluge..." onChange={(e) => setServiceDescription(e.target.value)}></textarea>
            <label style={{ margin: 'auto 1rem auto 0' }}>Nova pod usluga</label>
            <div className="service-subcategory-container">
                <div style={{ marginBottom: '1rem' }} className="service-subservice-item service-subservice-item-new" >
                    <input
                        type="text"
                        placeholder="Ime pod usluge"
                        name="Title"
                        className="title-input"
                        value={newSubServiceTitle}
                        onChange={(event) => setNewSubServiceTitle(event.target.value)} />

                    <input
                        type="text"
                        placeholder="Redirekt URL"
                        name="URL"
                        className="url-input"
                        value={newSubServiceUrl}
                        onChange={(event) => setNewSubServiceUrl(event.target.value)} />
                    <button className="new-btn" onClick={() => createNewTapped()}>
                        <div style={{ display: 'flex', padding: '0 0.7rem' }}>
                            Kreiraj
                        <FontAwesomeIcon icon={faCheck} />
                        </div>
                    </button>
                </div>
                {
                    subServices.length > 0 ? <label style={{ margin: 'auto 1rem auto 0' }}>Usluge</label> : <> </>
                }
                {
                    subServices.map((item, index) => (
                        <div className="service-subservice-item">
                            <input
                                type="text"
                                placeholder="Ime pod usluge"
                                name="Title"
                                className="title-input"
                                required value={item.title}
                                onChange={(event) => updateTitleForSubService(index, event)} />

                            <input
                                type="text"
                                placeholder="Redirekt URL"
                                name="URL"
                                className="url-input"
                                required value={item.redirect_url}
                                onChange={(event) => updateUrlForSubService(index, event)} />
                            <button className="delete-btn" onClick={() => deleteSubService(index)}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        </div>
                    ))
                }

            </div>
            <div className="button-container">
                <button onClick={() => handleSave()}>Save</button>
                <button onClick={() => handleDelete()}>Delete</button>
            </div>
        </div >
    )
}

export default ServiceEdit;
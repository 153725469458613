import React from 'react';
import UslugeEdit from './UslugeEdit';
import ListaUsluga from './ListaUsluga';
import fire from '../../Helpers/fire';
const Services = () => {
    const fetchServices = async () => {
        const db = fire.firestore()
        const data = await db.collection('services').get()
        const mapped = data.docs.map(doc => {
            let item = doc.data()
            item.id = doc.id
            return item
        });

        setServices(mapped)
    }
    React.useState(() => {
        fetchServices()
    }, [])

    const [services, setServices] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState(-1)

    const deletedItem = () => {
        fetchServices()
        setSelectedRow(-1)
    }
    const selectNew = () => {
        console.log('NEWW')
        var newServices = services
        newServices.unshift({
            "id": "",
            "imageUrl": "",
            "descriptionText": "",
            "title": ""
        })
        setServices(newServices)
        setSelectedRow(-1)
        setSelectedRow(0)
    }
    const createdNew = () => {
        fetchServices()
        fetchServices()
        setSelectedRow(-1)
    }

    return (
        <div className="service-container">

            <ListaUsluga services={services} selectedRow={selectedRow} setSelectedRow={setSelectedRow} selectNew={selectNew} />
            {selectedRow !== -1 ? (
                <UslugeEdit services={services} selectedRow={selectedRow} createdNew={createdNew} deletedItem={deletedItem} />
            ) : (
                    <div className="nothing-selected-container">
                        {/* <h2> Select a category</h2> */}
                    </div>
                )}
        </div>
    )
}

export default Services

import React from 'react';
import fire from '../../Helpers/fire';
//UI
import FileUploader from '../../Helpers/FileUploader';
import Creatable from 'react-select/creatable';

const GalleryItemOverlay = ({ setShowOverlay, editingItem, categories, createNewItem }) => {
    const [galleryItemName, setGalleryItemName] = React.useState(editingItem ? editingItem.title : '')
    const [galleryItemCategory, setGalleryItemCategory] = React.useState(editingItem ? editingItem.type : '')
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [selectedFile2, setSelectedFile2] = React.useState(null);

    /**
    * Save function
    * if current state is editing, only update current gallery item
    * otherwise, create new gallery item, save to firebase
    * and upload to storage
    */
    const handleSave = async () => {
        if (editingItem) {
            const result = await fire.firestore().collection('gallery').doc(editingItem.id).set({
                "title": galleryItemName,
                "type": galleryItemCategory,
            }, { merge: true })
            console.log(result)
            const object = {
                id: editingItem.id,
                title: galleryItemName,
                type: galleryItemCategory,
                imageUrl: editingItem.imageUrl,
                imageUrl2: editingItem.imageUrl2,
            }
            await uploadFiles(object)
        } else {
            if (galleryItemName === '') {
                alert('Morate Staviti ime')
                return
            }
            if (galleryItemCategory === '') {
                alert('Morate staviti kategoriju')
                return
            }

            if (selectedFile === null || selectedFile2 === null) {
                alert('Morate staviti slike.')
                return
            }
            const result = await fire.firestore().collection('gallery').add({
                "title": galleryItemName,
                "imageUrl": '',
                "type": galleryItemCategory
            })
            const object = {
                id: result.id,
                title: galleryItemName,
                type: galleryItemCategory
            }
            await uploadFiles(object)
        }

        setShowOverlay(false)
        createNewItem()
    }
    /**
      * Logic for deleting single gallery storage item
      */
    const deleteSingleFile = async (item, imageIndex) => {
        console.log('starting delete')
        const storage = fire.storage().ref()
        const imageExtension = item.imageUrl.includes('jpg') ? 'jpg' : 'png'
        const galleryRef = `gallery/${item.id}_${imageIndex}.${imageExtension}`
        return await storage.child(galleryRef).delete()
    }
    /**
    * Logic for uploading single gallery storage item
    */
    const uploadSingleFile = async (file, id, index) => {
        console.log('starting upload')
        const storage = fire.storage().ref()
        const extType = file.type === "image/png" ? ".png" : ".jpg"
        const fullPath = `gallery/${id}_${index}${extType}`
        const upload = await storage.child(fullPath).put(file)
        const fileUrl = await upload.ref.getDownloadURL()
        return fileUrl
    }

    /**
    * Uploading 2 files.
    */
    const uploadFiles = async (galleryItem) => {
        console.log(galleryItem)
        const galleryID = galleryItem.id
        console.log('starting upload')
        if (selectedFile) {
            if (editingItem) {
                await deleteSingleFile(galleryItem, 1)
            }
            const fileOne = await uploadSingleFile(selectedFile, galleryID, '1')
            await fire.firestore().collection('gallery').doc(galleryID).set({
                "imageUrl": fileOne
            }, { merge: true })
        }
        if (selectedFile2) {
            if (editingItem) {
                await deleteSingleFile(galleryItem, 2)
            }
            const fileTwo = await uploadSingleFile(selectedFile2, galleryID, '2')
            await fire.firestore().collection('gallery').doc(galleryID).set({
                "imageUrl2": fileTwo
            }, { merge: true })
        }
        return true
    }

    return (
        <div className="overlay-popup" >
            {
                editingItem ? <h2 className="overlay-title-label" >Izmena</h2> : <h2 className="overlay-title-label" >Nova slika</h2>
            }
            <div className="overlay-title">
                <label>Ime </label>
                <input type="text"
                    id="gallery-item-title"
                    onChange={element => setGalleryItemName(element.target.value)}
                    required value={galleryItemName} />
            </div>
            {console.log('item about to get')}
            {console.log(galleryItemCategory)}
            <Creatable className="select-gallery-filter"
                options={categories}
                onChange={option => setGalleryItemCategory(option.value)}
                defaultValue={editingItem ? { 'label': galleryItemCategory, 'value': galleryItemCategory } : ''} />

            <FileUploader
                inputName="Before picture"
                handleFile={(file) => setSelectedFile(file)}
                imageUrl={editingItem ? editingItem.imageUrl : ''}
            />

            <FileUploader
                inputName="After picture"
                handleFile={(file) => setSelectedFile2(file)}
                imageUrl={editingItem ? editingItem.imageUrl2 : ''}
            />
            <div className="button-container" >
                <button onClick={() => handleSave()} style={{ background: "rgba(82, 99, 113, 1)", marginTop: "1rem" }}> Save </button>
                <button onClick={() => setShowOverlay(false)} style={{ background: "rgba(162, 42, 44, 1)", marginTop: "1rem" }}> close </button>
            </div>
        </div>
    )
}

export default GalleryItemOverlay
import React from 'react';
import fire from '../Helpers/fire'
import { NavLink } from 'react-router-dom'
const Nav = () => {

    const style = {
        color: 'white',
        textDecoration: 'none'
    }
    return (
        <div className="hero">
            <nav className="nav">
                <NavLink to="/"><img className="logo" alt="logo" src='https://www.magicdent.co.rs/wp-content/uploads/2018/03/magicdentlogo-1.png' /></NavLink>

                <button className="logout-btn" onClick={handleLogout}> Logout</button>
            </nav>
            <ul className="nav-links">
                <NavLink style={style} activeClassName="nav-link-item-selected" to="/services"><li key='services'>Usluge</li></NavLink>
                <NavLink style={style} activeClassName="nav-link-item-selected" to="/gallery"><li key='gallery'>Galerija</li></NavLink>
                <NavLink style={style} activeClassName="nav-link-item-selected" to="/facts"><li key='interesting'>Zanimljivosti</li></NavLink>
                <NavLink style={style} activeClassName="nav-link-item-selected" to="/other"><li key='other'>Ostalo</li></NavLink>
            </ul>
        </div>

    );

}
const handleLogout = () => {
    fire.auth().signOut();
}
export default Nav;
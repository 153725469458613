import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

let element = document.getElementById('root')
let app = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

ReactDOM.render(app, element)

import firebase from 'firebase';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBYFc9rlhcNXHs3GZmoahGVIoe7qxBLFOI",
    authDomain: "magicdent-56c23.firebaseapp.com",
    databaseURL: "https://magicdent-56c23.firebaseio.com",
    projectId: "magicdent-56c23",
    storageBucket: "magicdent-56c23.appspot.com",
    messagingSenderId: "134749967412",
    appId: "1:134749967412:web:4fdc3016461d2c304d2661"
};
// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);

export default fire;


import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import fire from './Helpers/fire'
import Login from './components/Login'
import Nav from './components/Nav'
import HomePage from './components/Home/Home';
import Services from './components/Usluge/Usluge';
import Facts from './components/Zanimljivosti/Zanimljivosti';
import Gallery from './components/Galerija/Galerija';
import Other from './components/Ostalo/Ostalo';
function App() {
  let userDefinition: any = null;
  const [user, setUser] = useState(userDefinition);

  const authListener = () => {
    fire.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        // Send to Login
        setUser('');
      }
    });
  }

  useEffect(() => {
    authListener();
  }, []);

  return (
    user ? <Router>
      <div className="app">
        <Nav />
        <div className="app-container">
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/facts" component={Facts} />
            <Route path="/services" component={Services} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/login" component={Login} />
            <Route path="/other" component={Other} />
          </Switch>
        </div>

      </div>
    </Router> : <Login />


  );
}

export default App;
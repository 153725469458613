import React from 'react';


const ServiceList = ({ services, selectedRow, setSelectedRow, selectNew }) => {

    return (
        <div className="service-list-container">

            <div className="service-list">
                <h2>Lista usluga</h2>
                <ul>
                    {services.map((serv, index) => (
                        <li
                            key={serv.id}
                            className={selectedRow === index ? "service-item-selected" : "service-item"}
                            onClick={() => setSelectedRow(index)}>
                            {serv.id === "" ? "Nova kategorija" : serv.title}
                        </li>
                    ))}
                </ul>

            </div>
            {
                services.map(item => item.id).includes("") ? (
                    <> </>
                ) : (
                        <button className="new-service-btn" onClick={() => selectNew()}> Dodaj novu</button>
                    )
            }
        </div>
    )
}
export default ServiceList;
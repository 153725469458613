import React, { useState } from 'react';
import fire from '../Helpers/fire'
const Login = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [hasAccount, setHasAccount] = useState(false);

    const clearErrors = () => {
        setEmailError('')
        setPasswordError('');
    }
    const handleLogin = () => {
        clearErrors();
        fire.auth().signInWithEmailAndPassword(email, password)
            .catch(error => {
                switch (error.code) {
                    case 'auth/invalid-email':
                    case 'auth/user-disabled':
                    case 'auth/user-not-found':
                        setEmailError(error.message);
                        break;
                    case 'auth/wrong-password':
                        setPasswordError(error.message)
                        break;
                    default: break

                }
            });
    }

    const handleSignup = () => {
        clearErrors();
        fire.auth().createUserWithEmailAndPassword(email, password)
            .catch(error => {
                switch (error.code) {
                    case 'auth/email-already-in-use':
                    case 'auth/invalid-email':
                        console.log('errr')
                        setEmailError(error.message);
                        break;
                    case 'auth/weak-password':
                        setPasswordError(error.message);
                        break;
                    default: break;
                }
            });
    }



    return (
        <section className="login">
            <div className="loginContainer">
                <label> Username</label>
                <input type="text" autoFocus required value={email} onChange={(e) => setEmail(e.target.value)} />
                <p className="errorMsg">{emailError}</p>
                <label>Password</label>
                <input type="password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                <p className="errorMsg">{passwordError}</p>
                <div className="btnContainer">
                    {hasAccount ? (
                        <>
                            <button onClick={handleLogin}>Sign in</button>
                            <p>Don't have an account? <span onClick={() => setHasAccount(!hasAccount)}>Sign Up</span></p>
                        </>
                    ) : (
                            <>
                                <button onClick={handleSignup}>Sign up</button>
                                <p>Have an account? <span onClick={() => setHasAccount(!hasAccount)}>Sign in</span></p>
                            </>
                        )}
                </div>
            </div>
        </section>
    );
}

export default Login;
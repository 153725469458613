import React, { useState } from 'react'
import fire from '../../Helpers/fire'

const Other = () => {
    const fetchData = async () => {
        const result = await fire.database().ref('settings').once('value')
        settings = result.val()
        setPromoImageUrl(settings.promoImageUrl)
    }
    useState(() => {
        fetchData()
    }, [])
    let settings = {}
    const [promoImageUrl, setPromoImageUrl] = useState('')

    const saveNewPromoImageUrl = (text) => {
        setPromoImageUrl(text)
        settings.promoImageUrl = text
        fire.database().ref('/settings').update(settings);

    }
    return (
        <div className="other-container">
            <h2> Ostala podešavanja.</h2>
            <div className="promo-image-url-container">
                <label>Promo</label>
                <input type="text" value={promoImageUrl} placeholder="Promo image url" id="gallery-item-title" onChange={element => saveNewPromoImageUrl(element.target.value)} />
            </div>
        </div>
    )
}
export default Other
import React from 'react';
import fire from '../../Helpers/fire';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import FactOverlay from './FactOverlay'
const Facts = () => {
    const [facts, setFacts] = React.useState([]);
    const [showOverlay, setShowOverlay] = React.useState(false);
    const [editingItem, setEditingItem] = React.useState(null);


    const fetchFacts = async () => {
        const db = fire.firestore()
        const data = await db.collection('facts').get()
        const mapped = data.docs.map(doc => {
            let item = doc.data()
            item.id = doc.id
            return item
        });

        setFacts(mapped)
    }
    React.useState(() => {
        fetchFacts()
    }, [])

    const deleteItem = async (item) => {
        await fire.firestore().collection('facts').doc(item.id).delete()
        const imageExtension = item.imageUrl.substr(item.imageUrl - 3);
        const galleryRef = `facts/${item.id}.${imageExtension}`
        await fire.storage().ref().child(galleryRef).delete()
        fetchFacts()
    }
    const editItemClicked = (item) => {
        setEditingItem(item)
        setShowOverlay(true)
    }
    const createItemClicked = () => {
        setEditingItem(null)
        setShowOverlay(true)
    }
    return (
        <div className="gallery">

            {
                showOverlay ?
                    <div id="overlay">
                        <FactOverlay
                            setShowOverlay={setShowOverlay}
                            editingItem={editingItem}
                            createNewItem={() => fetchFacts()} />
                    </div> :
                    <> </>
            }
            <button onClick={() => createItemClicked()} className="new-button">
                Dodaj novu činjenicu
            </button>
            <div className="facts-grid-container">
                {
                    facts.map((item) => (
                        <div className="facts-grid-item" key={item.id}>
                            <img src={item.imageUrl} alt="imageurl" />
                            <p id="fact-item-description">{item.description}</p>
                            <button className="edit-btn" onClick={() => editItemClicked(item)} >
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </button>
                            <button className="delete-btn bottom-right-btn" onClick={() => deleteItem(item)}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default Facts



import React from 'react';
import fire from '../../Helpers/fire';

import GalleryItemOverlay from './GalleryItemOverlay';
import GalleryGridItem from './GalleryGridItem';


const Gallery = () => {
    const [gallery, setGallery] = React.useState([]);
    const [showOverlay, setShowOverlay] = React.useState(false);
    const [editingItem, setEditingItem] = React.useState(null);
    const [selectedCategory, setSelectedCategory] = React.useState('Sve');


    /**
     * Filter throught gallery array and map all of the categories
     */
    const getCategories = () => {
        let categories = gallery.map(item => item.type)
        let sorted = categories.filter((item, index) => categories.indexOf(item) === index)
        sorted.splice(0, 0, 'Sve');
        return sorted
    }

    /**
    * Filter throught gallery array and map all of the categories
    * And create new object for Creatable UI element
    * Used for dropdown
    */
    const getCategoriesForDropdown = () => {
        let categories = gallery.map(item => item.type)
        let sorted = categories.filter((item, index) => categories.indexOf(item) === index)
        let finalArr = []
        sorted.forEach(item => {
            const itemObject = {
                'value': item,
                'label': item
            }
            finalArr.push(itemObject)
        })
        return finalArr
    }

    /**
    * Gets gallery items for selected category name
    */
    const getItemsForSelectedCategory = () => {
        if (selectedCategory === 'Sve') {
            return gallery
        } else {
            let categories = gallery.filter(item => item.type === selectedCategory)
            return categories
        }
    }

    /**
    * Fetch entire gallery and maps to current state object, which reloads UI
    */
    const fetchGallery = async () => {
        const db = fire.firestore()
        const data = await db.collection('gallery').get()
        const mapped = data.docs.map(doc => {
            let item = doc.data()
            item.id = doc.id
            return item
        });

        setGallery(mapped)
    }

    /**
    * On page load, fetch gallery
    */
    React.useState(() => {
        fetchGallery()
        console.log('fetching gallery')
    }, [])

    /**
    * Delete gallery item from firebase
    * Reload UI afterwards
    */
    const deleteItem = async (item) => {
        const imageExtension = item.imageUrl.includes('jpg') ? 'jpg' : 'png'
        const galleryRef = `gallery/${item.id}_1.${imageExtension}`
        const galleryRef2 = `gallery/${item.id}_2.${imageExtension}`
        await fire.storage().ref().child(galleryRef).delete()
        await fire.storage().ref().child(galleryRef2).delete()
        await fire.firestore().collection('gallery').doc(item.id).delete()
        setGallery(gallery.filter(galleryItem => galleryItem.id !== item.id))
    }

    /**
    * Create new gallery item tapped
    * Show overlay but set isEditing to false
    */
    const createNewItemTapped = () => {
        setEditingItem(null)
        setShowOverlay(true)
    }
    const editItemClicked = (item) => {
        setEditingItem(item)
        setShowOverlay(true)
    }

    /**
    * Grid View
    */
    const gridContainerView = (
        <div className="gallery-grid-container" >
            {
                getItemsForSelectedCategory().map((item) => (
                    <GalleryGridItem
                        item={item}
                        editItemClicked={editItemClicked}
                        deleteItemClicked={deleteItem} />
                ))
            }
        </div>
    )

    /**
    * Gallery category list view
    */
    const galleryCategoryView = (
        <ul className="gallery-category-list" >
            {
                getCategories().map(category =>
                    <li
                        key={category}
                        className={selectedCategory === category ? "category-filter-item-active" : "category-filter-item"}
                        onClick={() => setSelectedCategory(category)}>
                        {category}
                    </li>)
            }
        </ul>
    )

    /**
    * Create/Edit overlay view
    */
    const overlayView = (
        showOverlay ?
            <div id="overlay">
                {console.log('Overlay set for item', editingItem)}
                <GalleryItemOverlay setShowOverlay={setShowOverlay} editingItem={editingItem} categories={getCategoriesForDropdown()} createNewItem={() => fetchGallery()} />
            </div > :
            <> </>
    )
    return (
        <div className="gallery" >
            {overlayView}
            <button onClick={() => createNewItemTapped()} className="new-button">Dodaj novu sliku</button>
            {galleryCategoryView}
            {gridContainerView}
        </div>
    )
}


export default Gallery
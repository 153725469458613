import React from 'react';
import fire from '../../Helpers/fire';
import FileUploader from '../../Helpers/FileUploader';


const FactOverlay = ({ setShowOverlay, editingItem, createNewItem }) => {

    const [factDescription, setFactDescription] = React.useState(editingItem ? editingItem.description : '')
    const [selectedFile, setSelectedFile] = React.useState(null);

    const handleSave = async () => {
        const db = fire.firestore()
        if (factDescription === '') {
            alert('Morate uneti opis')
            return
        }
        if (selectedFile === null && editingItem === null) {
            alert('Morate uneti sliku')
            return
        }
        if (editingItem) {
            await fire.firestore().collection('facts').doc(editingItem.id).set({
                "description": factDescription
            }, { merge: true })

            const object = {
                id: editingItem.id,
                description: factDescription,
                imageUrl: editingItem.imageUrl
            }
            if (selectedFile) {
                await deletePreviousImage(editingItem)
                await uploadFile(object.id)
            }

        } else {
            const result = await db.collection('facts').add({
                "imageUrl": '',
                "description": factDescription
            })
            await uploadFile(result.id)
        }

        setShowOverlay(false)
        createNewItem()
    }
    const deletePreviousImage = async (item) => {
        const storage = fire.storage().ref()
        const imageExtension = item.imageUrl.includes('jpg') ? 'jpg' : 'png'
        const galleryRef = `facts/${item.id}.${imageExtension}`
        return await storage.child(galleryRef).delete()
    }
    const uploadFile = async (factID) => {
        console.log('Uploading fact image...')
        const storage = fire.storage().ref()
        const extType = selectedFile.type === "image/png" ? ".png" : ".jpg"
        const fullPath = `facts/${factID}${extType}`
        const upload = await storage.child(fullPath).put(selectedFile)
        const downloadURL = await upload.ref.getDownloadURL()
        return await fire.firestore().collection('facts').doc(factID).set({
            "imageUrl": downloadURL,
        }, { merge: true })
    }

    return (
        <div className="overlay-popup">
            { editingItem ? <h2 className="overlay-title-label">Izmena činjenice</h2> : <h2 className="overlay-title-label">Nova činjenica</h2>}
            <FileUploader
                inputName="Izaberi sliku"
                handleFile={(file) => setSelectedFile(file)}
                imageUrl={editingItem ? editingItem.imageUrl : ''}
            />
            <div className="overlay-textarea">
                <label>Opis</label>
                <textarea
                    type="text"
                    id="fact-item-title"
                    onChange={element => setFactDescription(element.target.value)}
                    required value={factDescription}
                />
            </div>

            <div className="button-container">
                <button onClick={() => handleSave()} style={{ background: "rgba(82, 99, 113, 1)", marginTop: "1rem" }}> Save</button>
                <button onClick={() => setShowOverlay(false)} style={{ background: "rgba(162, 42, 44, 1)", marginTop: "1rem" }}> close</button>
            </div>
        </div >
    )
}


export default FactOverlay
import React from 'react'

const FileUploader = ({ inputName, handleFile, imageUrl }) => {
    const [fileInput, setFileInput] = React.useState('')

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        setFileInput(URL.createObjectURL(fileUploaded))
        handleFile(fileUploaded);
    };

    return (
        <div className="file-uploader">
            {
                (fileInput !== '') ? (
                    <img src={fileInput} id="file-uploader-preview-image" alt="file input" />
                ) : (imageUrl !== '') ? (
                    <img src={imageUrl} id="file-uploader-preview-image" alt="url" />
                ) : (<> </>)
            }
            <button onClick={handleClick}>{inputName}</button>
            <input accept="image/png,image/gif,image/jpeg" type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />
        </div>
    )
}
export default FileUploader